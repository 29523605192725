{
    "General": {
        "Video": {
            "Titles": [
                "GIFA 2023",
                "Product Quality Message",
                "GIFA 2019"
            ],
            "NotSupported": "Su navegador no es compatible con la etiqueta de video."
        },
        "Address": {
            "Company": "Newform David S. Gower e. K.",
            "Street": "Berliner Allee 65",
            "Location": "64295 Darmstadt",
            "Country": "Alemania"
        },
        "PhoneNumbers": [
            "+49 (0)6151 3975-210",
            "+49 (0)6151 3975-111"
        ],
        "ReadMore": "Leer más...",
        "Download": "Descargar información",
        "Dengge": "Diseñado y desarrollado por"
    },
    "Navigation": {
        "Home": "Inicio",
        "Products": "Productos",
        "News": "Noticias",
        "Application": "Aplicación",
        "AboutUs": "Sobre nosotros",
        "Privacy": "Privacidad",
        "Contact": "Contacto",
        "LegalNotice": "Pie de imprenta",
        "Back": "Volver"
    },
    "Home": {
        "Document": {
            "Title": "Newform - Inicio"
        },
        "Header": {
            "Title": "Bienvenida a Newform",
            "Description": "Le asesoramos sobre los materiales de revestimiento de mica, así como sobre el aislamiento eléctrico y térmico (también biodegradable) en su fundición. Y suministramos material de mica antideslizante (muscovita o flogopita) para el revestimiento de su horno de bobina de inducción una vez que el material refractario necesite ser sustituido."
        },
        "News": [
            {
                "Title": "GIFA 2023"
            },
            {
                "Title": "GIFA 2019"
            },
            {
                "Title": "GIFA 2015"
            }
        ],
        "Products": [
            {
                "Title": "Combi G"
            },
            {
                "Title": "Foil PGM"
            }
        ]
    },
    "Products": {
        "Document": {
            "Title": "Newform - Productos"
        },
        "Header": {
            "Title": "Productos",
            "Description": "Láminas de aislamiento"
        },
        "Products": [
            {
                "Description": "Descripción:",
                "Application": "Aplicación:"
            },
            {
                "Title": "Combi G",
                "Description": "flexible y dúctil laminado mica fina (flogopita) y fibras de silicato de calcio con un aglutinante de amianto especial resistente a la rotura",
                "Application": "IVersión de la película de superposición de la vieja prensa de material refractario para hornos de inducción sin transporte"
            },
            {
                "Title": "Foil PGM",
                "Description": "Material de aislamiento de conformación en frío a partir de flogopita (alta temperatura de aplicación) y Moscovita (alta resitencia eléctrica) con alta elasticidad y estabilidad mecánica, fibra de vidrio reforzada y resistente a los desgarros",
                "Application": "Película de recubrimiento para el prensado de la vieja material refractario en la entrega de hornos de inducción"
            }
        ]
    },
    "News": {
        "Document": {
            "Title": "Newform - Noticias"
        },
        "Header": {
            "Title": "Noticias",
            "Description": ""
        },
        "Years": [
            {
                "Year": "Año 2023",
                "News": [
                    {
                        "Title": "",
                        "Metadata": "",
                        "Content": ""
                    }
                ]
            },
            {
                "Year": "Año 2019",
                "News": [
                    {
                        "Title": "GIFA 2019",
                        "Metadata": "25 de junio de 2019, por: Newform",
                        "Content": "El equipo de GIFA en el Hall 10 F77 Judith, Alla, David, Ankit, Uwe y Jan, le da la bienvenida a un animado intercambio sobre planchas de mica, material refractario y aislamientos microporosos asi como el servicio de montaje de los mismos. En el estand se sirven bebidas, cafe y un delicioso almuerzo a base de espaguetti."
                    }
                ]
            },
            {
                "Year": "Año 2015",
                "News": [
                    {
                        "Title": "Plano de deslizamiento de mica para fundiciones ferrosas y no ferrosas",
                        "Metadata": "31 de agosto de 2015, por: Newform",
                        "Content": "Aplicación eficaz de los hornos de bobina de inducción sin núcleo Las sensibles bobinas de cobre refrigeradas por agua de los hornos de inducción están protegidas por una pared de hormigón (lechada de la bobina) contra el refractario (aislamiento térmico). La eliminación del material refractario desgastado con un taladro de martillo es cara y peligrosa. Una capa de mica resistente al desgarro permite separar la masa refractaria resid..."
                    },
                    {
                        "Title": "GIFA 2015",
                        "Metadata": "29 de marcha de 2015, por: Newform",
                        "Content": "En la GIFA, Newform participará con sus socios de Brasil y España. Reúnase con nosotros en el pabellón 10, stand F77, para charlar, tomar un espresso y comer pasta. Newform: Revestimiento de mica para fundiciones de hierro gris - Hornos de bobina de inducción. Z-Tech: Refractarios de Al para grandes hornos de Al AMV ALEA: Software de carga optimizado para el taller de fundición Más: Altona - componentes de acero (alta ingeniería y calificación de tratamiento térmico) para la industria pesada"
                    }
                ]
            }
        ],
        "News": [
            {
                "Document": {
                    "Title": "Newform - Plano de deslizamiento de mica para fundiciones ferrosas y no ferrosas"
                },
                "Title": "Plano de deslizamiento de mica para fundiciones ferrosas y no ferrosas",
                "Content": [
                    "August 31st, 2015, by: Newform",
                    "Aplicación eficaz de los hornos de bobina de inducción sin núcleo",
                    "Las sensibles bobinas de cobre refrigeradas por agua de los hornos de inducción están protegidas por una pared de hormigón (lechada de la bobina) contra el refractario (aislamiento térmico). La eliminación del material refractario desgastado con un taladro de martillo es cara y peligrosa. Una capa de mica resistente al desgarro permite separar la masa refractaria residual (sinterizada) de la pared de hormigón al ser empujada hacia fuera y, por tanto, protege la bobina, prolonga la vida útil del refractario en el crisol y reduce los costes de mantenimiento.",
                    "Al revestir los hornos de bobina de inducción, el material refractario a base de SiO2 se introduce localmente en el crisol y se compacta para formar el aislamiento térmico de la bobina de la fundición. El aislamiento de aproximadamente 10 cm de espesor degenera, según la naturaleza de la fundición, después de unas 200 aplicaciones (2-6 semanas con hornos de media frecuencia).",
                    "La retirada de la masa y la escoria viejas y usadas y el revestimiento de los hornos de crisol con aislamiento térmico exigen precaución y experiencia. Conseguir que las superficies de la lechada del serpentín sean lo suficientemente lisas, de modo que no surjan defectos en la pared del serpentín, requiere mucho trabajo y tiempo.",
                    "Newform proporciona una capa a base de mica libre de amianto y biodegradable que sirve de plano de deslizamiento. El material aislante consiste en papel de mica reforzado con fibra de vidrio y laminado con aglutinante resistente al calor con papel de silicato de calcio si es necesario (en hornos más grandes, donde la pared es bastante irregular).",
                    "Este \"papel pintado\", generalmente de 100 cm de ancho y sin olor, se entrega en un rollo de 25 m de longitud. Se cortan al tamaño deseado y se montan verticalmente en el lado de la bobina del horno de crisol (Fig. 1) y se fijan en la parte superior con cola (acuosa y sin halógenos) o cinta adhesiva. El lado de la mica se muestra al interior del horno (Fig. 2). El revestimiento de mica garantiza un buen deslizamiento entre el material refractario y la lechada de la bobina.",
                    "La fibra aislante Newform Combi G es un laminado flexible, formado en frío, compuesto por fibra de flogopita y silicato de calcio con un aglutinante especial de silicona. La fibra de aproximadamente 2,4-2,5 mm de grosor para el aislamiento de hornos de inducción tolera temperaturas de hasta 1.050°C, compensa los desniveles de la superficie de la pared de hormigón, de sólo 2 cm de grosor, y reduce así las cavidades.",
                    "Newform Foil PGM, tiene un grosor aproximado de 0,4-0,5mm, reforzado con fibra de vidrio y se utiliza preferentemente para alisar las paredes interiores del horno que se encuentra en hornos de menos de 2 toneladas normalmente.",
                    "Eficaz e inofensivo para la salud y la ecología",
                    "Las fibras aislantes sin amianto y las láminas aislantes para hornos de inducción se basan en la mica muscovita o flogopita (Fig. 3). La mica es un silicato estratificado con una alta resistencia eléctrica. Es resistente al calor y, además, a casi todos los medios, como álcalis, gases, aceites y ácidos.",
                    "De acuerdo con el Reglamento de Sustancias Peligrosas 91/155/CEE, la mica no presenta riesgos para la salud, tanto en su almacenamiento como en su instalación y retirada. Por lo tanto, no hay que tomar ninguna medida especial respecto a la retirada de las fibras y películas aislantes para los hornos de inducción. El material se descompone en la naturaleza en sus componentes individuales y puede eliminarse de forma segura como escombros de construcción y vertederos de residuos municipales.",
                    "Newform ha ampliado su cartera de productos para las fundiciones y distribuye productos adyacentes para el taller de fundición, como un software especial para la carga optimizada de los materiales y aleaciones utilizados. Opera en 20 países con una red de agentes y socios que conocen el idioma local y los mercados nacionales correspondientes.",
                    "Autor y proveedor de imágenes: David S. Gower, Newform",
                    "www.newform.de",
                    "Teléfono: 06151-605210",
                    "Los revestimientos a base de mica son flexibles y fáciles de manejar, y han sustituido al amianto en la mayoría de los países del mundo, prestando atención a los productos no peligrosos para mantener la salud del personal de fundición.",
                    "Reforzadas con fibra de vidrio, no son tóxicas y generalmente se entregan en rollo (500mm o 1.000mm de ancho y 10, 20 o 25 m2 por rollo). Sirven como:",
                    "Mica <b>antideslizante</b> con alta resistencia a la tracción",
                    "<b>Capa de seguridad</b> que protege la bobina contra el material refractario",
                    "<b>Capa separadora</b> de difusión de gases",
                    "<b>Capa de aislamiento</b> de protección eléctrica y térmica",
                    "Productos principales:",
                    "Newform Combi G (papel de mica/alta temperatura de CaSi),",
                    "Newform Foil PGM (mica flogopita/muscovita reforzada con una estera de fibra de vidrio)"
                ]
            },
            {
                "Document": {
                    "Title": "Newform - GIFA 2015"
                },
                "Title": "GIFA 2015",
                "Content": [
                    "En la GIFA, Newform participará con sus socios de Brasil y España. Reúnase con nosotros en el pabellón 10, stand F77, para charlar, tomar un espresso y comer pasta.<br />Newform: Revestimiento de mica para fundiciones de hierro gris - Hornos de bobina de inducción.<br />Z-Tech: Refractarios de Al para grandes hornos de Al<br />AMV ALEA: Software de carga optimizado para el taller de fundición Más: Altona - componentes de acero (alta ingeniería y calificación de tratamiento térmico) para la industria pesada"
                ]
            },
            {
                "Document": {
                    "Title": "Newform - GIFA 2019"
                },
                "Title": "GIFA 2019",
                "Content": [
                    "El equipo de GIFA en el Hall 10 F77 Judith, Alla, David, Ankit, Uwe y Jan, le da la bienvenida a un animado intercambio sobre planchas de mica, material refractario y aislamientos microporosos asi como el servicio de montaje de los mismos. En el estand se sirven bebidas, cafe y un delicioso almuerzo a base de espaguetti.<br /><br />हॉल 10 F77 पर हमारी GIFA टीम, जिसमें शामिल हैं जूडिथ, अला, डेविड, अंकित, उवे और जॉन, निम्न विषयों पर जीवंत विनिमय के लिए आपका स्वागत करती है :-अभ्रक अस्तर, औद्योगिक दुर्दम्य सामग्री, सूक्ष्म छिद्र इन्सुलेशन, इनका कार्यान्वयन और प्रदत्त सेवाएँ।हम पेय, एस्प्रेसो और स्वादिष्ट स्पेगेटी भोजन परोसेंगे।<br /><br />Judith, Alla, David, Ankit, Uwe ve Jan ile Gifa ekibi olarak sizleri Salon 10 F77'de görmekten mutluluk duyacagiz. Sizleri mika astar, refrakter ve mikro gözenekli yalıtım malzemeleri hakkinda fikir alisverisine davet ediyoruz.Cesitli içecekler, espresso ve lezzetli spagetti öğle yemeği servisi sizlei bekliyor olacak.<br /><br />A equipe GIFA no Hall 10 F77 Judith, Alla, David, Ankit, Uwe e Jan,lhes dão as boas vindas para um animado Intercâmbio sobre placas de mica, material de isolamento refratário e micro poroso, bem como o serviço de montagem do mesmo. No stand Será servido bebidas, café e um delicioso almoço com espaguete."
                ]
            }
        ]
    },
    "Application": {
        "Document": {
            "Title": "Newform - Aplicación"
        },
        "Buttons": [
            "Instrucciones de manejo",
            "Editorial"
        ],
        "Header": {
            "Title": "Aplicación",
            "Description": ""
        },
        "Products": [
            {
                "Title": "Newform Combi G",
                "Description": "es un material aislante biológicamente disoluble y sin amianto, desarrollado teniendo en cuenta especialmente la técnica de aislamiento de los hornos de bobina de inducción. Su función principal: una capa de protección para la bobina de inducción. Ver PDF."
            }
        ]
    },
    "AboutUs": {
        "Document": {
            "Title": "Newform - Sobre nosotros"
        },
        "Header": {
            "Title": "Sobre nosotros",
            "Description": ""
        },
        "Title": "Newform gana nuevos clientes principales para sus películas mica Films",
        "Description": [
            "El proveedor especializado de películas multicapa para hornos de inducción es Gießereielieferant de grandes empresas en Alemania y más allá.",
            "Las transparencias de superposición de apoyo Newform en una forma muy efectiva para aumentar el proceso de fusión y la protección de la bobina de inducción a partir del material refractario. Además, se refuerzan una excelente barrera de difusión de gas, así como para blindaje térmico eléctrico. Se considera sin amianto cerámica y clasificado las películas de recubrimiento de acuerdo con la directiva de la CE como seguro.",
            "Incluso con su expansión internacional Newform ha recorrido un largo camino. Mediante la cooperación con agentes y distribuidores \"en el acto\" se ha abierto un acceso rentable a la industria europea de la fundición escandinavo y oriental.",
            "Países como Indonesia Kazajstán y se expanden, más recientemente, la clientela de la compañía con sede en Darmstadt, en el mercado internacional.",
            "El Akqusitionserfolge también refleja positivamente en las ventas. Desde mayo 2010 hasta 04 2011 Newform aumentó sus ventas con respecto al mismo periodo del año anterior en un 80 por ciento. La crisis mundial de 2008/2009 Newform ahora ha sido superado.",
            "Los próximos pasos son GIFA América Latina, España y Portugal. que buscan socios adecuados Newform"
        ],
        "Philosophy": {
            "Title": "Filosofía",
            "Quote": "“Ofrecemos un servicio altamente cualificado, combinado con productos innovadores para las necesidades diarias de nuestros clientes en la industria de la fundición.”",
            "Source": "- David S. Gower | 23 de agosto de 2013"
        },
        "Team": {
            "Title": "Conozca al equipo",
            "Member": [
                {
                    "Name": "David S. Gower",
                    "Description": "CEO"
                },
                {
                    "Name": "David Romain",
                    "Description": "Business Developer International"
                }
            ]
        }
    },
    "Contact": {
        "Document": {
            "Title": "Newform - Contacto"
        },
        "Header": {
            "Title": "Contacto"
        },
        "Title": "Por favor no dude en contactar con nosotros",
        "Description": "¿Le gustaría recibir más información de nuestras ofertas y servicios, por favor envíenos sus consultas.",
        "ContactInfo": "Información de Contacto",
        "Form": {
            "Name": "Nombre*",
            "Mail": "Correo electrónico*",
            "Address": "Dirección",
            "Phone": "Teléfono/Móvil*",
            "Message": "Mensaje*",
            "Submit": "Enviar",
            "Validation": {
                "Name": {
                    "Required": "Se requiere el nombre."
                },
                "Mail": {
                    "Required": "Se requiere el correo electrónico.",
                    "Valid": "El correo electrónico es invalido."
                },
                "Message": {
                    "Required": "Se requiere mensaje."
                },
                "Phone": {
                    "Required": "Se requiere teléfono//móvil.",
                    "Valid": "El teléfono/móvil no es válido."
                }
            },
            "Response": {
                "Success": [
                    "¡La solicitud fue enviada con éxito!",
                    "La contactaremos a la brevedad."
                ],
                "Fail": [
                    "¡Ocurrió un error!",
                    "Vuelve a intentarlo más tarde o contáctanos por correo electrónico."
                ],
                "Ok": "Ok"
            }
        }
    },
    "Footer": {
        "Contact": {
            "Title": "Contacto"
        },
        "Social": {
            "Title": "Medios de comunicación social:",
            "Links": [
                "LinkedIn"
            ]
        },
        "Newform": {
            "Title": "Newform"
        },
        "Affiliates": {
            "Title": "Afiliado"
        }
    },
    "Privacy": {
        "Document": {
            "Title": "Newform - Privacidad"
        },
        "Header": {
            "Title": "Política de privacidad",
            "Description": "Esta política de privacidad le informa sobre la naturaleza, el alcance y la finalidad del tratamiento de los datos personales (en lo sucesivo, \"datos\") en este sitio web. En cuanto a los términos utilizados, como \"datos personale\" o su \"tratamiento\", nos remitimos a las definiciones del artículo 4 del Reglamento General de Protección de Datos (GDPR)."
        },
        "Responsibility": {
            "Title": "Responsable"
        },
        "Datatypes": [
            "Tipos de datos que se tratan",
            "Datos de contacto (por ejemplo, correo electrónico, nombre)"
        ],
        "Purpose": {
            "Title": "Finalidad del tratamiento de datos",
            "Descriptions": [
                "Newform David S. Gower procesa sus datos en cumplimiento del Reglamento General de Protección de Datos (GDPR) y la Ley Federal de Protección de Datos (BDSG).",
                "Los datos registrados sólo se utilizarán para estos fines y no se transmitirán a recolectores de datos u otros."
            ],
            "Purposes": [
                "Contacto y gestión de contactos",
                "Al ponerse en contacto con Newform David S. Gower (a través del formulario de contacto, por teléfono o por correo electrónico), los datos del usuario se procesan con el fin de gestionar la solicitud de contacto y su procesamiento de acuerdo con el Art. 6 (1) lit. b) GDPR. Al ponerse en contacto con Newform David S. Gower a través del formulario de contacto, se enviará un correo electrónico generado automáticamente a la dirección de contacto. Newform David S. Gower se reserva el derecho de ponerse en contacto con el usuario de la forma que prefiera. La revocación de su consentimiento ya concedido es posible en cualquier momento. Un mensaje informal por correo electrónico es suficiente para la revocación. La legalidad de las operaciones de tratamiento de datos realizadas hasta la revocación no se verá afectada por ésta."
            ]
        },
        "Duration": {
            "Title": "Duración del almacenamiento de datos",
            "Description": "Sus datos se eliminarán en cuanto ya no sean necesarios para la tramitación de los fines mencionados. Newform David S. Gower almacena sus datos si estamos legalmente obligados a hacerlo. Estas obligaciones se derivan, entre otras, del HGB y del AO."
        },
        "ThirdParties": {
            "Title": "Transmisión de datos a terceros",
            "Description": "Los datos que Newform David S. Gower recoge no serán revelados a terceros."
        },
        "ThirdCountries": {
            "Title": "Transmisión de datos a terceros países",
            "Description": "Los datos que Newform David S. Gower recoge no se transmitirán a un proveedor de servicios fuera de la zona de la UE/EEE."
        },
        "Complaints": {
            "Title": "Derecho de recurso",
            "Description": [
                "Tiene derecho a ponerse en contacto con el responsable de la protección de datos mencionado anteriormente o con una autoridad de control de la protección de datos para presentar una reclamación, por ejemplo, la autoridad de control responsable en el estado federal de su residencia o la autoridad que nos corresponda como organismo responsable. Aquí encontrará una lista de autoridades de control (para el sector no público) con su ",
                "dirección."
            ],
            "Rights": [
                "Sus derechos de protección de datos",
                "Puede ejercer los siguientes derechos en cualquier momento a través de los datos de contacto facilitados por nuestro responsable de protección de datos:",
                "Información sobre sus datos almacenados por Newform David S. Gower y su procesamiento (Art. 15 GDPR),",
                "Corrección de datos personales incorrectos (Art. 16 GDPR),",
                "Eliminación de sus datos almacenados por Newform David S. Gower (Art. 17 GDPR),",
                "Restricción del procesamiento de datos si Newform David S. Gower aún no puede eliminar sus datos debido a obligaciones legales (Art. 18 GDPR),",
                "La objeción al tratamiento de sus datos por parte de Newform David S. Gower (Art. 21 GDPR) y la portabilidad de los datos, siempre que haya consentido el tratamiento de datos o haya celebrado un contrato con nosotros (Art. 20 GDPR).",
                "Si ha dado su consentimiento a Newform David S. Gower, puede revocarlo en cualquier momento con efecto para el futuro."
            ]
        },
        "Changes": {
            "Title": "Cambios en la política de privacidad",
            "Description": "Newform David S. Gower se reserva el derecho a modificar la política de privacidad para adaptarla a los cambios en la situación legal o en caso de que se produzcan cambios en el servicio y en el tratamiento de los datos. Por ello, en caso de duda, le rogamos que vuelva a consultar la política de privacidad antes de transmitirnos sus datos personales."
        },
        "Time": "Agosto 2021"
    },
    "LegalNotice": {
        "Document": {
            "Title": "Newform - Pie de imprenta"
        },
        "Header": {
            "Title": "Pie de imprenta"
        },
        "ResponsibilityTitle": {
            "Inhaber": "Propietario:",
            "Verantwortlich": "Responsable de la Ley de Teleservicios:",
            "Amtsgericht": "Tribunal de distrito:",
            "VATNumber": "Número de identificación del IVA:",
            "Telefon": "Teléfono:",
            "Telefax": "Fax:"
        },
        "Responsibility": {
            "Title": "Newform David S. Gower e. K.",
            "Inhaber": "David S. Gower",
            "Verantwortlich": "David S. Gower",
            "Amtsgericht": "Amtsgericht Darmstadt, HRA 6258",
            "VATNumber": "DE 111512978",
            "Telefon": "+49 (0)6151 3975-210",
            "Telefax": "+49 (0)6151 3975-111"
        },
        "Disclaimer": {
            "Title": "Descargo de responsabilidad:",
            "Inhalt": [
                "Newform David S. Gower hace todos los esfuerzos razonables para proporcionar en este sitio web es la información precisa y completa disponible. Responsable o responsable de la información, especialmente en cuanto a la integridad, exactitud y actualidad, pero no se puede asumir.",
                "Queda excluida toda responsabilidad por los daños que se produzcan directa o indirectamente por el uso de este sitio web, salvo que se basen en dolo o negligencia grave de Newform David S. Gower o sus agentes.",
                "El sitio web de Newform David S. Gower contiene enlaces a otros sitios web. Newform David S. Gower no es responsable del contenido de estos otros sitios web y se desvincula expresamente de ellos. Newform David S. Gower se reserva el derecho de cambiar o modificar sin previo aviso la información proporcionada."
            ]
        }
    }
}